import { signIn, useSession } from "next-auth/react";
import { useEffect } from "react";
import { useRouter } from "next/router";
import Spinner from "@/components/layout/Spinner";
import { GetServerSideProps } from "next";
import { getServerSession } from "next-auth";
import { useCheckSession } from "@/components/hooks/useCheckSession";

export default function SignIn() {
  const router = useRouter();
  const status = useCheckSession();

  useEffect(() => {
    const { callbackUrl = '/' } = router.query || {};
    if (status === "unauthenticated") {
      void signIn("azure-ad-b2c",{callbackUrl:(callbackUrl as string)});
    } else if (status === "authenticated") {
      void router.push(callbackUrl as string);
    }
  }, [status]);

 

  return <Spinner />;
}
