import React from "react";

/**
 * Component to render spinner. Identical to TnSpinner except
 * it renders based on styles and classes props.
 *
 * @example
 * const message='Loading Message';
 * return (
 *   <Spinner message={message} />
 * );
 */
const Spinner = () => {
  return (
    <div className="w-full h-full absolute loader-backdrop z-99 inset-0 flex items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <div className="animate-spin w-[50px] h-[50px]">
          <div className="loader"></div>
        </div>
        <p className="text-base leading-5 mt-3">Loading...</p>
      </div>
    </div>
  );
};

export default Spinner;
