import { getSession } from "next-auth/react";
import { useEffect, useState } from "react";


export function useCheckSession(){
  const [status,setStatus]=useState<'loading'|'authenticated'|'unauthenticated'>('loading');

  useEffect(()=>{
    const checkSession=async ()=>{
      const session = await getSession();
      setStatus(session!=null?'authenticated':'unauthenticated')
    };
    checkSession();
  },[]);

  return status;
}